body{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    width: 100%;
}

.header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 1.5rem;
}

.osdata {
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
}

.executores {
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
    margin-bottom: 2rem;
}
.ateste {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    align-items: center;
    margin-bottom: 2rem;
}

.execucao {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 70%;
    align-self: center;
}

.data {
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
    margin-top: 1rem;
    margin-bottom: 2rem;
}

.footer {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-top: 2rem;
}

.subTitle {
    color: #3faae0
}



@page {
    size: A4;
  }

